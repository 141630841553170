/*
 * @Author: 覃伟锋 792575027@qq.com
 * @Date: 2023-11-06 10:07:51
 * @LastEditors: 覃伟锋 792575027@qq.com
 * @LastEditTime: 2023-11-09 21:02:45
 * @FilePath: \DigitalTwin-main\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 适配flex
import "@/common/flexible.js";
// 引入全局css
import "./assets/scss/style.scss";
//引入echart
import * as echarts from "echarts";
import three3d from "./common/three3d/index";
import loading from "./views/components/loading";
import dbStorage from "./utils/indexedDB";
Vue.use(ElementUI);
Vue.use(three3d);
Vue.component("Loading", loading);
Vue.prototype.$echarts = echarts;
Vue.prototype.$dbStorage = dbStorage; // indexdb数据库
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.directive("drag", {
  bind: function(el) {
    el.onmousedown = (e) => {
      let disX = e.clientX - el.offsetLeft;
      let disY = e.clientY - el.offsetTop;
      document.onmousemove = (e) => {
        let left = e.clientX - disX;
        let top = e.clientY - disY;
        el.style.left = left + "px";
        el.style.top = top + "px";
      };
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  },
});
