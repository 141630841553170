<!--
 * @Author: 龙行锋 792575027@qq.com
 * @Date: 2023-11-12 00:14:01
 * @LastEditors: 龙行锋 792575027@qq.com
 * @LastEditTime: 2023-11-14 19:19:22
 * @FilePath: \DigitalTwin-y\src\common\three3d\TLight\src\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script>
import * as THREE from "three";
export default {
  name: "TLight",
  inject: ["global"],
  methods: {
    loadLight([x, y, z]) {
      const scene = this.global.scene;
      var spotLight = new THREE.DirectionalLight(0xffffff, 1);
      spotLight.position.set(x, y, z);
      scene.add(spotLight);
    },
  },
  mounted() {
    const arr = [
      [30, 30, 30],
      [-30, 30, 30],
      [30, -30, 30],
      [30, 30, -30],
    ];
    arr.forEach((_) => {
      this.loadLight(_);
    });
    const scene = this.global.scene;
    var ambient = new THREE.AmbientLight(0x000000, 1);
    // var ambient = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambient);
  },
  render() {
    return null;
  },
};
</script>
