export default {
  state: {
    leftMenu: false,
    header: false,
    rightMenu: false,
    bottomMenu: false,
    bodyFootMenu: false,
    modelPlanVisible: false,
    currentType: null,
    currentLineId: null,
  },
  mutations: {
    // setBarTitle(state, data) {
    //   state.barInfo.title = data;
    // },
  },
  actions: {
    // setBarTitle({ commit }, data) {
    //   commit("setBarTitle", data);
    // },
  },
  getters: {
    // barTitle: (state) => state.barInfo.title,
  },
};
