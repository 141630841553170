<!--
 * @Author: 龙行锋 792575027@qq.com
 * @Date: 2023-11-12 00:14:01
 * @LastEditors: 龙行锋 792575027@qq.com
 * @LastEditTime: 2023-11-30 20:41:49
 * @FilePath: \DigitalTwin-y\src\common\three3d\TCamera\src\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script>
import { PerspectiveCamera } from "three";
export default {
  name: "TCamera",
  inject: ["global"],
  mounted() {
    const { w, h } = this.global.rendererSize;
    const camera = new PerspectiveCamera(30, w / h, 0.1, 1000);
    camera.position.set(
      -10.957725648134131,
      15.121389482888364,
      12.58199867911272
    );
    camera.lookAt(0, 0, 0);
    camera.name = "camera";
    this.global.camera = camera;
  },
  render() {
    return null;
  },
};
</script>
