<!--
 * @Author: 覃伟锋 792575027@qq.com
 * @Date: 2023-11-06 10:07:50
 * @LastEditors: 覃伟锋 792575027@qq.com
 * @LastEditTime: 2023-11-09 22:21:44
 * @FilePath: \DigitalTwin-main\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {  
    return {  
      windowWidth: 0,  
      windowHeight: 0  
    };  
  },  
  mounted() {  
    this.initWindowSize();  
    window.addEventListener('resize', this.windowResized);  
  },  
  beforeDestroy() {  
    window.removeEventListener('resize', this.windowResized);  
  },  
  methods: {  
    initWindowSize() {  
      this.windowWidth = window.innerWidth;  
      this.windowHeight = window.innerHeight;  
    },  
    windowResized() {  
      if (this.windowWidth !== window.innerWidth || this.windowHeight !== window.innerHeight) {  
        this.windowWidth = window.innerWidth;  
        this.windowHeight = window.innerHeight;  
        location.reload(); // 重新加载页面  
      }  
    }  
  }  
};
</script>


<style>
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "clock";
  src: url("./assets/font/DigifaceWide.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}
</style>
